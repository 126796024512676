// TransactionTable.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Transaction from '../../../constants/transaction';

const TransactionTable = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<Transaction[]>(`${apiUrl}/tickets`);
        setTransactions(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const getValidationStyle = (validationStatus: number) => {
    // Define estilos, incluyendo padding y bordes redondeados, solo para la palabra dentro de la celda
    return validationStatus === 1
      ? { backgroundColor: 'green', color: 'white', padding: '5px', borderRadius: '5px' }
      : { backgroundColor: 'red', color: 'white', padding: '5px', borderRadius: '5px' };
  };

  return (
    <div>
      <h1>Lista de Transacciones</h1>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr style={{ background: '#f2f2f2', height: '40px'  }}>
            <th>ID</th>
            <th>Fecha de Compra</th>
            <th>Cliente</th>
            <th>Total Amount</th>
            <th>Nro Transacción</th>
            <th>Código de Validación</th>
            <th>Estado de Validación</th>
            <th>Fecha de Validación</th>
            <th>Email del Cliente</th>
            <th>DNI</th>
            <th>Entradas</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) => (
            <tr key={transaction.id} style={{ borderBottom: '1px solid #ddd', height: '40px'  }}>
              <td>{transaction.id}</td>
              <td>{transaction.purchase_date}</td>
              <td>{transaction.customer.name}, {transaction.customer.surname}</td>
              <td style={{textAlign: 'right'}}>$ {transaction.total_amount}</td>          
              <td style={{textAlign: 'right'}}>{transaction.transaction_number}</td>
              <td style={{textAlign: 'right'}}>{transaction.validation_code}</td>
              <td style={{textAlign: 'center'}}>
              <span style={getValidationStyle(transaction.validation_status)}>
                  {transaction.validation_status === 1 ? 'Validado' : 'No Validado'}
                </span>

              </td>
              <td>{transaction.validation_date || ''}</td>
              <td>{transaction.customer.email}</td>
              <td>{transaction.customer.identification_number}</td>
              <td style={{textAlign: 'right'}}>{transaction.ticket_details_count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionTable;
