import React, { ReactNode, createContext, useContext, useState } from 'react';
import { TicketType } from '../../constants/ticketType';


interface TicketContextType {
  selectedTickets: { [key: string]: number };
  setSelectedTickets: React.Dispatch<React.SetStateAction<{ [key: string]: number }>>;
  personalData: { [key: string]: string };
  setPersonalData: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  totalPrice: number;
  setTotalPrice: React.Dispatch<React.SetStateAction<number>>;
  ticketDetails: TicketType[];
  setTicketDetails: React.Dispatch<React.SetStateAction<TicketType[]>>;
}

const TicketContext = createContext<TicketContextType | undefined>(undefined);

export const TicketProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedTickets, setSelectedTickets] = useState<{ [key: string]: number }>({
    Mayores: 0,
    Menores: 0,
    Jubilados: 0,
    Estacionamiento: 0,
  });

  const [personalData, setPersonalData] = useState<{ [key: string]: string }>({
    surname: '',
    name: '',
    identificationNumber: '',
    email: '',
  });

  const [totalPrice, setTotalPrice] = useState<number>(0);

  const [ticketDetails, setTicketDetails] = useState<TicketType[]>([]);

  const ticketContextValues: TicketContextType = {
    selectedTickets,
    setSelectedTickets,
    personalData,
    setPersonalData,
    totalPrice,
    setTotalPrice,
    ticketDetails,
    setTicketDetails,
  };

  return <TicketContext.Provider value={ticketContextValues}>{children}</TicketContext.Provider>;
};

export const useTicketContext = () => {
  const context = useContext(TicketContext);
  if (!context) {
    throw new Error('useTicketContext debe ser usado dentro de un TicketProvider');
  }
  return context;
};
