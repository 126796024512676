import { createBrowserRouter } from 'react-router-dom';
import Home from '../Page/Home/Home';
import Prices from '../Page/Prices/Prices';
import Tickets from '../Page/Tickets/Tickets';
import Us from '../Page/Us/Us';
import Success from '../Page/Success/Success';
import Login from '../Page/Login/Login';
import Dashboard from '../Page/Dashboard/Dashboard';
import TransactionTable from '../Page/Dashboard/Tickets/TransactionTable';

export const router = createBrowserRouter([
	{
		path: '/',
		element: <Home />,
	},
	{
		path: '/prices',
		element: <Prices />,
	},
	{
		path: '/us',
		element: <Us />,
	},
	{
		path: '/tickets',
		element: <Tickets />,
	},
	{
		path: '/success',
		element: <Success />,
	},
	{
		path: '/login',
		element: <Login />,
	},
	{
		path: '/dashboard',
		element: <Dashboard />,
	}
	,
	{
		path: '/dashboard/tickets',
		element: <TransactionTable />,
	}
]);
