import React from 'react';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  const containerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  };

  const cardStyle: React.CSSProperties = {
    backgroundColor: '#ffffff',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    width: '300px',
    textAlign: 'center',
  };

  const formStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
  };

  const labelStyle: React.CSSProperties = {
    marginBottom: '5px',
  };

  const inputStyle: React.CSSProperties = {
    padding: '8px',
    marginBottom: '15px',
    border: '1px solid #ccc',
    borderRadius: '3px',
  };

  let buttonStyle: React.CSSProperties = {
    backgroundColor: '#007bff',
    color: '#fff',
    padding: '10px',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };

  const buttonHoverStyle: React.CSSProperties = {
    backgroundColor: '#0056b3',
  };

  const handleLogin = () => {
    // Aquí puedes agregar la lógica de autenticación, por ahora simplemente redirigimos a /dashboard
    navigate('/dashboard');
  };

  return (
    <div style={containerStyle}>
      <div style={cardStyle}>
        <h2>Iniciar Sesión</h2>
        <form style={formStyle}>
          <label style={labelStyle} htmlFor="username">
            Usuario:
          </label>
          <input style={inputStyle} type="text" id="username" name="username" />

          <label style={labelStyle} htmlFor="password">
            Contraseña:
          </label>
          <input style={inputStyle} type="password" id="password" name="password" />

          <button
            style={buttonStyle}
            onMouseOver={() => (buttonStyle = { ...buttonStyle, ...buttonHoverStyle })}
            onMouseOut={() => (buttonStyle = { ...buttonStyle })}
            onClick={handleLogin}
          >
            Iniciar Sesión
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
